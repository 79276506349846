<template>
  <div id="links-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/links" class="btn btn-primary mb-5 ml-3">
      Zurück zur Übersicht
    </router-link>


    <div class="card">
      <div class="card-header">
        <h3>Link Tree bearbeiten</h3>
      </div>
      <div class="card-body">
        <h4>Bearbeite oder lösche Seiten aus deinem aktuellen Link Tree</h4>
        <p>
          Vorsicht: Wenn du manuell Seiten löscht kann es sein, dass dein Link Tree-Graph möglicherweise nicht mehr richtig
          dargestellt wird.
        </p>

        <NodeSelector :nodes="nodes"
                      :selected-node-url="selectedNodeUrl"
                      :exclude-items="['links']"
                      wrapper-classes="border rounded p-3 m-3 mb-4"
                      node-size="col-3"
                      @selectNode="selectNode($event)"
                      :accordion="true"
        ></NodeSelector>

        <div v-if="selectedNodeId">
          <h5>{{ selectedNode.title }}</h5>
          <h6>Details:</h6>
          <ul>
            <li>
              {{ connectedLinks.length }} {{ connectedLinks.length === 1 ? 'Link' : 'Links' }}
              <ul>
                <li>{{ outgoingLinks.length }} {{ outgoingLinks.length === 1 ? 'ausgehender Link' : ' ausgehende Links' }}</li>
                <li>{{ incomingLinks.length }} {{ incomingLinks.length === 1 ? 'eingehender Link' : ' eingehende Links' }}</li>
              </ul>
            </li>
            <li>Zu {{ connectedNodes.length }} {{ connectedNodes.length === 1 ? 'anderer Seite bzw. Website' : 'anderen Seiten bzw. Websites' }}</li>
          </ul>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6">
            <button @click="deleteNode" class="btn btn-block btn-danger">
              Seite löschen
            </button>
          </div>
          <div class="col-sm-6">
            <router-link :to="'/links/' + selectedNodeId" class="btn btn-block btn-primary">
              Seite bearbeiten
            </router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

import NodeSelector from "@/components/NodeSelector";

export default {
  name: 'LinksEdit',
  components: {
    NodeSelector,
  },
  data() {
    return {
      selectedNodeId: "",
    }
  },
  computed: {
    nodes() {
      return this.project.netNodes;
    },
    links() {
      return this.project.netLinks;
    },
    selectedNode() {
      return this.nodes.find(n => n.id === this.selectedNodeId);
    },
    selectedNodeUrl() {
      return this.selectedNode ? this.selectedNode.url : "";
    },
    connectedLinks() {
      return this.links.filter(l => l.sid === this.selectedNodeId || l.tid === this.selectedNodeId);
    },
    outgoingLinks() {
      return this.connectedLinks.filter(l => l.sid === this.selectedNodeId);
    },
    incomingLinks() {
      return this.connectedLinks.filter(l => l.tid === this.selectedNodeId);
    },
    connectedNodes() {
      return this.nodes.filter(n => this.connectedLinks.find(l => l.tid === n.id));
    }
  },
  watch: {
  },
  methods: {
    selectNode(node) {
      this.selectedNodeId = node.id;
    },
    deleteNode() {
      let id = this.selectedNodeId;
      this.selectedNodeId = "";
      this.project.netLinks.forEach((link, index) => {
        if (link.sid === id || link.tid === id) {
          this.project.netLinks.splice(index, 1);
        }
      });
      let index = this.project.netNodes.findIndex(node => node.id === id);
      this.project.netNodes.splice(index, 1);
    },
    disconnectNode(id) {
      let nodeIndex = this.connectedNodes.findIndex(n => n.id === id);
      this.connectedNodes.splice(nodeIndex, 1);

      let linkIndex = this.connectedLinks.findIndex(l => l.tid === id);
      this.connectedLinks.splice(linkIndex, 1);
    },
    save() {
      // TODO: Sync Server
      /*this.$store.dispatch('updateProjectByProp', { prop: 'jobs', data: this.project.journeys })
          .then(res => {
            console.log(res);
          });*/
    }
  },
  beforeMount() {
  },
  mounted() {
  }
}
</script>